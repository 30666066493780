.menubar {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 20px;
  align-items: center;
  width: 100%;
  padding: 0 40px;
  margin-top: 52px;
  position: relative;

  .circle {
    top: -16px;
    width: 9px;
    height: 9px;
    background-color: black;
    border-radius: 9px;
    position: absolute;
    transition: 0.3s ease-in-out;
  }

  a {
    display: block;
  }

  .logo {
    grid-column: 2 / 6;
    min-width: 290px;
    min-height: 32px;
    font-size: 34px;
    line-height: 34px;
    font-weight: 800;
    color: #f50000;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    span {
      margin-left: 23px;
      &::after {
        content: "вещи";
      }
    }

    &.animatedLogo {
      span::after {
        animation-name: animatedLogo;
        animation-duration: 3s;
        animation-iteration-count: 1;
      }
    }

    @keyframes animatedLogo {
      0% {
        content: "футболки";
      }
      15% {
        content: "носки";
      }
      30% {
        content: "свитера";
      }
      45% {
        content: "пальто";
      }
      50% {
        content: "рюкзаки";
      }
      60% {
        content: "джинсовки";
      }
      65% {
        content: "джинсы";
      }
      70% {
        content: "худи";
      }
      75% {
        content: "платья ";
      }
      80% {
        content: "штаны";
      }
      85% {
        content: "пуховики";
      }
      89% {
        content: "рубашки";
      }
      93% {
        content: "сумки";
      }
      97% {
        content: "лонгсливы";
      }
      100% {
        content: "вещи";
      }
    }
  }

  .main-links {
    grid-column: 10 / 16;
    display: flex;
    justify-self: center;

    a {
      color: black;
      text-transform: lowercase;
      text-decoration: none;
      margin-right: 40px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #686868;

      &:hover,
      &.current {
        color: black;
      }
    }
  }

  .contact {
    grid-column: 18 / 25;
    display: flex;
    justify-self: end;
    margin-right: 53px;

    a {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #686868;
      &:hover {
        color: black;
      }

      &:nth-child(n+0):not(:last-child) {
        margin-right: 24px;
      }

      &::before {
        width: 18px;
        height: 20px;
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
        margin-right: 10px;
      }

      &.telegram {
        &::before {
          background-image: url("../images/tg-icon.svg");
          margin-top: 4px;
        }
      }

      &.instagram {
        &::before {
          background-image: url("../images/insta-icon-black.svg");
          margin-top: 2px;
        }
      }

      &.vk {
        &::before {
          background-image: url("../images/iconmonstr-vk-1.svg");
          margin-top: 2px;
        }
      }

      &.wa {
        &::before {
          background-image: url("../images/whatsapp-icon.svg");
        }
      }
    }

    .notice {
      width: 230px;
      height: 96px;
      padding: 18px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background-color: white;
      border-radius: 12px;
      border: 1px solid #999999;
      text-align: center;
      position: absolute;
      top: 52px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .menubar {

    .logo {
      grid-column: 1/6;
    }

    .contact {
      margin-right: 0;
    }

    .main-links {
      grid-column: 9/15;
    }
  }
}

@media screen and (max-width: 1080px) {
  .menubar {
    .contact {
      grid-column: 19 / 25;
    }
  }
}

@media screen and (max-width: 900px) {
  .menubar {
    .logo {
      grid-column: 1 / 6;
      font-size: 28px;
      line-height: 28px;
    }

    .main-links {
      grid-column: 10 / 16;

      a {
        margin-right: 24px;
      }
    }

    .contact {
      grid-column: 18 / 25;
    }
  }
}

.menubarMobile {
  .menubarClosed {
    padding: 24px 28px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: block;
      width: 164px;
      height: 41px;
      background-image: url("../images/logo-full.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .burger {
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      div {
        width: 16px;
        height: 2px;
        background-color: black;

        &:not(:last-of-type) {
          margin-bottom: 4px;
        }
      }
    }
  }

  .menubarOpen {
    .bg {
      top: 0;
      z-index: 1000;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .menubarCard {
      .mainSection {
        position: fixed;
        z-index: 1001;
        width: calc(100% - 20px);
        margin: 0 auto;
        right: 10px;
        top: 10px;
        background-color: white;
        border-radius: 18px;
        padding: 28px 18px 30px 20px;
      }

      .bottomSection {
        position: fixed;
        z-index: 1001;
        width: calc(100% - 20px);
        margin: 0 auto;
        right: 10px;
        background-color: white;
        border-radius: 18px;
        padding: 28px 30px 20px 20px;
        bottom: 10px;
      }

      .close {
        width: 32px;
        height: 32px;
        background-image: url("../images/close.svg");
        background-size: 13px 13px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 18px;
        top: 24px;
      }

      .circle {
        width: 8px;
        height: 8px;
        background-color: black;
        border-radius: 9px;
        position: absolute;
      }

      .main-links {
        a {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          text-transform: lowercase;
          color: black;
          margin-right: 30px;
        }
      }

      .contact {
        display: flex;
        flex-wrap: wrap;

        a {
          display: flex;
          color: black;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-right: 32px;

          &::before {
            width: 18px;
            height: 20px;
            content: "";
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            margin-right: 12px;
          }

          &.telegram {
            &::before {
              background-image: url("../images/tg-icon.svg");
              margin-top: 4px;
            }
          }

          &.instagram {
            &::before {
              background-image: url("../images/insta-icon-black.svg");
              margin-top: 1px;
            }
          }

          &.vk {
            &::before {
              background-image: url("../images/iconmonstr-vk-1.svg");
              margin-top: 2px;
            }
          }

          &.wa {
            &::before {
              background-image: url("../images/whatsapp-icon.svg");
              margin-top: 1px;
            }
          }
        }
      }

      .notice {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #686868;
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .menubarMobile .menubarOpen .menubarCard .main-links a {
    margin-right: 15px;
  }

  .menubarMobile .menubarOpen .menubarCard .contact a::before {
    margin-right: 8px;
  }

  .menubarMobile .menubarOpen .menubarCard .contact a {
    margin-right: 24px;
  }

  .menubarMobile .menubarOpen .menubarCard .contact a:last-of-type {
    margin-right: 0;
  }
}

