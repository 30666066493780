@import "./reset.css";

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Regular.woff");
  src: url("../fonts/Manrope-Regular.woff2") format("woff2"),
    url("../fonts/Manrope-Regular.otf") format("opentype"),
    url("../fonts/Manrope-Regular.woff2") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Medium.woff");
  src: url("../fonts/Manrope-Medium.woff2") format("woff2"),
    url("../fonts/Manrope-Medium.otf") format("opentype"),
    url("../fonts/Manrope-Medium.woff2") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-SemiBold.woff");
  src: url("../fonts/Manrope-SemiBold.woff2") format("woff2"),
    url("../fonts/Manrope-SemiBold.otf") format("opentype"),
    url("../fonts/Manrope-SemiBold.woff2") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Bold.woff");
  src: url("../fonts/Manrope-Bold.woff2") format("woff2"),
    url("../fonts/Manrope-Bold.otf") format("opentype"),
    url("../fonts/Manrope-Bold.woff2") format("truetype");
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: "Manrope", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

body {
  font-family: "Manrope";
  background-color: #fcfcfc;

  a {
    text-decoration: none;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.popup-notice {
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  max-width: 600px;
  text-align: center;

  height: 52px;
  line-height: 52px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 52px;
  padding: 0 32px;

  color: white;
  font-weight: 500;

  animation-name: appear;
  animation-duration: 5s;
  animation-iteration-count: 1;

  @keyframes appear {
    0% {
      bottom: -52px;
    }

    10% {
      bottom: 40px;
    }

    90% {
      bottom: 40px;
    }

    100% {
      bottom: -52px;
    }
  }
}

@media screen and (max-width: 768px) {
  .popup-notice {
    width: calc(100vw - 40px);
    height: auto;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 32px;
  }
}

@import "./loading.scss";
@import "./blank_popup.scss";
@import "./menubar.scss";
@import "./main_page.scss";
@import "./blanks_page.scss";
@import "./custom_page.scss";
@import "./about_us_page.scss";
@import "./faq_page.scss";
@import "./request_form.scss";
@import "./footer.scss";
@import "./conf_page.scss";
