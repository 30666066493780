.confPage {
  margin-top: 100px;
  width: 100vw;
  padding: 0 40px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 20px;

  div {
    grid-column: 2 / 23;
    font-size: 20px;
    line-height: 28px;
    max-width: 900px;
    margin: 0 auto;

    h1 {
      font-size: 44px;
      line-height: 48px;
      font-weight: 500;
      margin-bottom: 40px;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    p + h1 {
      margin-top: 100px;
    }

    p + h2 {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 768px) {
  .confPage {
    margin-top: 60px;
    width: 100%;
    max-width: 100%;
    grid-column-gap: 0;
    padding: 0 16px;
    grid-template-columns: repeat(1, 1fr);

    div {
      grid-column: 1 / 1;
      max-width: calc(100vw - 32px);

      h1 {
        font-size: 30px;
        line-height: 38px;
        font-weight: 500;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      p + h1 {
        margin-top: 100px;
      }

      p + h2 {
        margin-top: 60px;
      }
    }
  }
}
